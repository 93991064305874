import React, { useState } from "react";

/* plugin */
import { useAutoAnimate } from "@formkit/auto-animate/react";
import DOMPurify from "dompurify";
/* plugin end */

const sanitizeConfig = {
  ALLOWED_ATTR: ["target", "href", "rel"],
};

const FaqAccordComp = ({ title, description }) => {
  const [animationParent] = useAutoAnimate();
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`faq-details ${isActive ? "active" : ""}`}>
      <div className="faq-ques" onClick={() => setIsActive(!isActive)}>
        <h2 className="comm-head-4">{title}</h2>
        <div className="right-arrow">
          <span className="icon-arrow-down" />
        </div>
      </div>
      <div ref={animationParent}>
        {isActive && (
          <div
            className="faq-ans comm-body"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description, sanitizeConfig),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FaqAccordComp;
