const { REACT_APP_ASSET_URL } = process.env;

export const GROWMORE_DATA = [
    {
        imgSrc: `${REACT_APP_ASSET_URL}/properties/grow-1.svg`,
        title: "Get Rewarded",
        para: "Lorem Ipsum lorem ipsum valar"
    },
    {
        imgSrc: `${REACT_APP_ASSET_URL}/properties/grow-2.svg`,
        title: "Get Rewarded",
        para: "Lorem Ipsum lorem ipsum valar"
    },
    {
        imgSrc: `${REACT_APP_ASSET_URL}/properties/grow-3.svg`,
        title: "Get Rewarded",
        para: "Lorem Ipsum lorem ipsum valar"
    },
    {
        imgSrc: `${REACT_APP_ASSET_URL}/properties/grow-1.svg`,
        title: "Get Rewarded",
        para: "Lorem Ipsum lorem ipsum valar"
    },
    {
        imgSrc: `${REACT_APP_ASSET_URL}/properties/grow-2.svg`,
        title: "Get Rewarded",
        para: "Lorem Ipsum lorem ipsum valar"
    },
    {
        imgSrc: `${REACT_APP_ASSET_URL}/properties/grow-3.svg`,
        title: "Get Rewarded",
        para: "Lorem Ipsum lorem ipsum valar"
    },
]

export const RESALE_DATA = [
    {
        img: `${REACT_APP_ASSET_URL}/properties/resale-1.png`,
        title: 'Kensington Waters',
        city: 'Meydan One, Dubai',
        price: 'USD 464',
    },
    {
        img: `${REACT_APP_ASSET_URL}/properties/resale-1.png`,
        title: 'Kensington Waters',
        city: 'Meydan One, Dubai',
        price: 'USD 464',
    },
    {
        img: `${REACT_APP_ASSET_URL}/properties/resale-1.png`,
        title: 'Kensington Waters',
        city: 'Meydan One, Dubai',
        price: 'USD 464',
    },
    {
        img: `${REACT_APP_ASSET_URL}/properties/resale-1.png`,
        title: 'Kensington Waters',
        city: 'Meydan One, Dubai',
        price: 'USD 464',
    },
]

